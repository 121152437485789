import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import VideoComponent from "./ui/video.component";

export default function WaitingComponent(props) {
    const { t } = useTranslation();

    const [videos, setVideos] = useState([
        {
            name: t("meeting.start.videos.video1"),
            uri: "https://upgrade-estate.be/investeren/loop5"
        },
        {
            name: t("meeting.start.videos.video2"),
            uri: "d-Faw_JmyVI"
        },
        {
            name: t("meeting.start.videos.video3"),
            uri: "https://youreka-virtualtours.be/tours/upkot/"
        },
    ]);

    const [timeUntilMeeting, setTimeUntilMeeting] = useState(getTimeUntilMeeting(props.date));
    const [videoView, setVideoView] = useState();
    const [video, setVideo] = useState(false);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTimeUntilMeeting(getTimeUntilMeeting(props.date));
        }, 1000);

        return () => clearInterval(intervalId);
    }, [props.date]);

    return (
        <div class="dark" style={{ paddingBottom: "0" }}>
            <h2 className="text-4xl font-semibold mt-12">{t("meeting.start.patience")}</h2>
            <p className="mt-8 mb-8">
            {t("meeting.start.advice")}
            </p>
            <br />
            <br />
            <div style={{ maxWidth: "320px", width: "50%", margin: "0 auto" }}>
                <div className="embed-container" style={{ margin: "25% auto 20%" }}>
                    <div className="embed options">
                        {videos.map((video) => (
                            <button
                                className="btn option"
                                onClick={() => setVideoView(video.uri) & setVideo(true)}
                            >
                                {video.name}
                            </button>
                        ))}
                    </div>
                    <div className="tv" />
                </div>
            </div>
            <div style={{ maxWidth: "750px", margin: "-160px auto -60px", display: video ? "none" : "block" }}>
                <div className="clock">
                    <div className="time">
                        <div className="days">{t("meeting.start.remaining")}{' '}{timeUntilMeeting.days} {timeUntilMeeting.days > 1 || timeUntilMeeting.days == 0 ? t("meeting.start.days") : t("meeting.start.day")}{' '}{t("meeting.start.and")}</div>

                        <div className="cou">{timeUntilMeeting.hours.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}:</div>
                        <div className="cou">{timeUntilMeeting.minutes.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}:</div>
                        <div className="cou">{timeUntilMeeting.seconds.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}</div>

                    </div>
                </div>
            </div>
            <VideoComponent uri={videoView} open={video} setOpen={setVideo} />
        </div>
    );
}

function getTimeUntilMeeting(meetingDatetime) {
    const currentTime = new Date();
    const meetingTime = new Date(meetingDatetime);
    const timeDifference = meetingTime - currentTime;

    const secondsUntilMeeting = Math.floor(timeDifference / 1000);
    const minutesUntilMeeting = Math.floor(secondsUntilMeeting / 60);
    const hoursUntilMeeting = Math.floor(minutesUntilMeeting / 60);
    const daysUntilMeeting = Math.floor(hoursUntilMeeting / 24);

    return {
        days: daysUntilMeeting > 0 ? daysUntilMeeting : 0,
        hours: hoursUntilMeeting % 24 > 0 ? hoursUntilMeeting % 24 : 0,
        minutes: minutesUntilMeeting % 60 > 0 ? minutesUntilMeeting % 60 : 0,
        seconds: secondsUntilMeeting % 60 > 0 ? secondsUntilMeeting % 60 : 0,
    };
}