import api from "./api/api";
import TokenService from "./api/token";

class AuthService {
   requestToken(email) {
    return api.post("/auth/login/", {
      'email': email
    });
  }

  loginToken(token) {
    return api.post("/auth/login/token/", {
        'token': token
      })
      .then(response => {
        if (response.data) {
          TokenService.setUser(response.data);
        }
        return response.data;
      });
    }

  me() {
    return api.get("/auth/me/");
    }

  logout() {
    TokenService.removeUser();
  }

  getCurrentUser() {
    return TokenService.getUser();
  }

  switchLang(lang) {
    return api.post("/auth/lang/", {
      'lang': lang
    });
  }
}

export default new AuthService();