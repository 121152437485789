import api from "./api/api";
import TokenService from "./api/token";

class VotingService {
  getQuestions(id) {
    return api.get("/voting/question/list/" + id + "/");
  }

  createQuestion(id, question) {
    return api.post("/voting/question/create/", {
      "meeting_id": id,
      "question": question
    });
  }
 
  stopQuestion(meeting_id, id) {
    return api.post("/voting/question/stop/", {
      "id": id,
      "meeting_id": meeting_id
    });
  }

  vote(id, option, votingid) {
    return api.post("/voting/vote/putvote/" + votingid + "/", {
      "meeting_id": id,
      "vote": option
    });
  }

  getVoting(id) {
    return api.get("/voting/question/live/" + id + "/");
  }

  getVote(id) {
    return api.get("/voting/vote/vote/" + id + "/");
  }

  getLiveVote(id) {
    return api.get("/voting/question/livequestion/" + id + "/");
  }

  getAllVotes(id) {
    return api.get("/voting/vote/votes/" + id + "/");
  }

  removeVote(id, option, votingid) {
    return api.post("/voting/vote/removevote/" + votingid + "/", {
      "meeting_id": id,
      "vote": option
    });
  }

  manualVoteAdd(id, question, option) {
    return api.post("/voting/question/manualvote/add/", {
      "meeting_id": id,
      "question_id": question,
      "vote": option
    });
  }

  manualVoteRemove(id, question, option) {
    return api.post("/voting/question/manualvote/remove/", {
      "meeting_id": id,
      "question_id": question,
      "vote": option
    });
  }

}

export default new VotingService();