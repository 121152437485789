import { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

//import services
import MeetingAdminService from "../../services/meeting.admin.service"
import ChatService from "../../services/chat.service";
import { socket, SocketContext } from "../../services/socket.service";
import VotingService from "../../services/voting.service";

//import components
import ChatComponent from "../../components/chat.component";
import VoteComponent from "../../components/admin/vote.component";


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function ManageMeeting() {
    const [status, setStatus] = useState("not started");
    const [av, setAV] = useState({});
    
    const [avStatsLive, setAVStatsLive] = useState(0);
    const [avStatsAttends, setAVStatsAttends] = useState(0);
    const [reloadStats, setReloadStats] = useState(0);

    const [chat, setChat] = useState([]);
    const [message, setMessage] = useState("");

    const [votingTitle, setVotingTitle] = useState("");
    const [currentVote, setCurrentVote] = useState(0);
    const [currentVotes, setCurrentVotes] = useState([]);

    const [questions, setQuestions] = useState([]);
    const [votes, setVotes] = useState(0);

    const [reload, setReload] = useState(0);

    const { id } = useParams();
    const socketprovider = useContext(SocketContext);

    const [scrollTop, setScrollTop] = useState(0);
    

    useEffect(() => {
        var count = 0;
        for (var i = 0; i < questions.length; i++) {
            if (questions[i].votes != null) {
                count += questions[i].votes.length;
            }
        }
        setVotes(count);
    }, [questions]);

    const stats = [
        { name: 'Kijkers', stat: avStatsLive, previousStat: avStatsAttends},
        { name: 'Chatberichten', stat: chat.length },
        { name: 'Stemmen', stat: votes + currentVotes.yes + currentVotes.no + currentVotes.abstain },
    ]

    useEffect(() => {
        MeetingAdminService.getMeeting(id)
            .then(response => {
                document.title = "Assemblo - Admin - " + response.data.title;
                setAV(response.data)
                if (response.data.started == true) {
                    setStatus("started");
                } else if (response.data.ended == true) {
                    setStatus("ended");
                } else {
                    setStatus("not started");
                }
            })
            .catch(err => {
                console.log(err);
            });

        ChatService.getChatsAdmin(id)
            .then(response => {
                setChat(response.data);
            }
            )
            .catch(err => {
                toast.error("Error getting chat");
            });

        VotingService.getLiveVote(id)
            .then(response => {
                if (response.data != "no live vote") {
                    VotingService.getAllVotes(response.data.id)
                        .then(response => {
                            setCurrentVotes(response.data);
                        })
                        .catch(err => {
                            console.log(err);
                        });
                    setCurrentVote(response.data.id);
                    setVotingTitle(response.data.question);
                    toast.success("Vote is actief.");
                }
            })
            .catch(err => {
                console.log(err);
            }
            );
    }, []);

    useEffect(() => {
        MeetingAdminService.getMeetingStats(id)
        .then(response => {
            setAVStatsLive(response.data.live);
            setAVStatsAttends(response.data.attends);
        }
        )
        .catch(err => {
            console.log(err);
        }
        );
    }, [reloadStats]);

    useEffect(() => {
        VotingService.getQuestions(id)
            .then(response => {
                setQuestions(response.data);
            }
            )
            .catch(err => {
                toast.error("Error getting questions");
            }
            );
    }, [reload]);

    function manageMeeting(status) {
        MeetingAdminService.status(id, status)
            .then(response => {
                console.log(response)
            })
            .catch(err => {
                console.log(err);
            });
    }

    useEffect(() => {
        socketprovider.connect();
        socketprovider.socket.on('meeting', function (data) {
            if (data.id == id && data.status == "start") {
                setStatus("started");
            }
            if (data.id == id && data.status == "end") {
                setStatus("ended")
            }
        });

        socketprovider.socket.on('chat', function (data) {
            setChat(chat => [...chat, { user: data.user, message: data.message, created_on: data.created_on }]);
        });

        socketprovider.socket.on('votes', function (data) {
            if (data.meeting_id == id) {
                setCurrentVotes({
                    "yes": data.yes,
                    "no": data.no,
                    "abstain": data.abstain
                });
            }
        });

        socketprovider.socket.on('admin', function (data) {
            setAVStatsLive(data.length);
        });

        socketprovider.socket.on("rsvp", function (data) {
            if (data.meeting_id == id) {
                setReloadStats(reloadStats => reloadStats + 1);
            }
        });


        return () => {
            socketprovider.socket.off('meeting');
            socketprovider.socket.off('chat');
            socketprovider.socket.off('votes');
            socketprovider.socket.off('admin');
            socketprovider.socket.off('rsvp');
            socketprovider.disconnect();
        }
    }, []);

    function sendNewChat() {
        if (!message == "") {
            socketprovider.socket.emit('chat', { id: id, message: message });
            setMessage("");
        }
    }

    function createQuestion() {
        if (!votingTitle == "") {
            VotingService.createQuestion(id, votingTitle)
                .then(response => {
                    setQuestions(questions => [...questions, response.data]);
                    setCurrentVote(response.data.id);
                    toast.success("Voting aangemaakt en gestart.");
                })
                .catch(err => {
                    console.log(err);
                    toast.err("Error creating question");
                });
        }
    }

    function stopQuestion() {
        VotingService.stopQuestion(id, currentVote)
            .then(response => {
                toast.success("Voting gestopt");
                setCurrentVote(0);
                setVotingTitle("");
                setReload(reload + 1)
            })
            .catch(err => {
                console.log(err);
                toast.err("Error stopping question");
            });
    }

    useEffect(() => {
        function onScroll() {
            let currentPosition = window.pageYOffset;
            setScrollTop(currentPosition <= 0 ? 0 : currentPosition);
        }

        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    }, [scrollTop]);

    function addVoteManual(vid) {
        if (vid == 1) {
            var vote = "yes";
        } else if (vid == 2) {
            var vote = "abstain";
        } else if (vid == 3) {
            var vote = "no";
        } else {
            toast.error("Error adding vote");
            return false;
        }

        VotingService.manualVoteAdd(id, currentVote, vote)
            .then(response => {
                toast.success("Vote toegevoegd");
            }
            )
            .catch(err => {
                toast.error("Error adding vote");
            }
            );
    }

    function subVoteManual(vid) {
        if (vid == 1) {
            var vote = "yes";
        }
        else if (vid == 2) {
            var vote = "abstain";
        }
        else if (vid == 3) {
            var vote = "no";
        }
        else {
            toast.error("Error adding vote");
            return false;
        }

        VotingService.manualVoteRemove(id, currentVote, vote)
            .then(response => {
                toast.success("Vote verwijderd");
            }
            )
            .catch(err => {
                toast.error("Error removing vote")
            }
            );

    }

    return (
        <div className="flex">
            <main className="flex-1 w-3/4">
                <div className="py-6">
                    <div className="flex mx-auto px-4 sm:px-6 md:px-8">
                        <div className="flex-1">
                            <div>
                                <h1 className="text-2xl font-semibold text-gray-900">AV beheer</h1>
                            </div>
                            <div>
                                <p>Online vergadering: <span className="font-bold">{av.title}</span> <span className="text-xs">(id: {id})</span></p>
                            </div>
                        </div>
                        <div>
                            <span className={`inline-flex items-center rounded-full ${status == "not started" ? "bg-gray-300" : status == "ended" ? "bg-red-200" : "bg-green-200"} px-3 py-0.5 text-sm font-medium ${status == "not started" ? "text-gray-900" : status == "ended" ? "text-red-900" : "text-green-900"}`}>
                                <svg className={`-ml-1 mr-1.5 h-2 w-2 ${status == "not started" ? "text-gray-600" : status == "ended" ? "text-red-600" : "text-green-600"}`} fill="currentColor" viewBox="0 0 8 8">
                                    <circle cx={4} cy={4} r={3} />
                                </svg>
                                {status == "not started" ? "NIET GESTART" : status == "ended" ? "AFGELOPEN" : "LIVE"}
                            </span>
                        </div>
                    </div>

                    <div className="mx-auto px-4 sm:px-6 md:px-8">
                        <dl className="mt-5 grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow md:grid-cols-3 md:divide-y-0 md:divide-x">
                            {stats.map((item) => (
                                <div key={item.name} className="px-4 py-5 sm:p-6">
                                    <dt className="text-base font-normal text-gray-900">{item.name}</dt>
                                    <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                                        <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
                                            {item.stat}
                                            {item.previousStat
                                                ? <span className="ml-2 text-sm font-medium text-gray-500">van de {item.previousStat}</span>
                                                : null
                                            }
                                        </div>
                                    </dd>
                                </div>
                            ))}
                        </dl>
                        <h1 className="text-xl font-semibold text-gray-900 mt-10">Controls</h1>

                        <div className="mt-2 relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-6 hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                            <div className="bg-white shadow sm:rounded-lg">
                                <div className="px-4 py-5 sm:p-6">
                                    <div className="sm:flex sm:items-start sm:justify-between">
                                        <div>
                                            <h3 className="text-lg font-medium leading-6 text-gray-900">AV Starten</h3>
                                            <div className="mt-2 max-w-xl text-sm text-gray-500">
                                                <p>
                                                    Deze actie is niet ongedaan te maken, dus zorg dat iedereen klaar is.<br /><br />
                                                    Controleer het tablad "Livestream" om te zien of de livestream werkt.<br />
                                                    Controleer het tablad "Genodigden" om te zien of alle genodigden aanwezig zijn.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="mt-5 sm:mt-0 sm:ml-6 sm:flex sm:flex-shrink-0 sm:items-center">
                                            <button
                                                onClick={() => manageMeeting("start")}
                                                className="inline-flex items-center justify-center rounded-md border border-transparent bg-green-100 px-4 py-2 font-medium text-green-700 hover:bg-green-200 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:text-sm disabled:hover:cursor-not-allowed disabled:opacity-50"
                                                disabled={status == "started" ? true : false}
                                            >
                                                START
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-white shadow sm:rounded-lg mt-5">
                                <div className="px-4 py-5 sm:p-6">
                                    <div className="sm:flex sm:items-start sm:justify-between">
                                        <div>
                                            <h3 className="text-lg font-medium leading-6 text-gray-900">AV Stoppen</h3>
                                            <div className="mt-2 max-w-xl text-sm text-gray-500">
                                                <p>
                                                    Deze actie is niet ongedaan te maken, dus zorg dat de AV zeker afgelopen is.<br />
                                                    Je kunt chat- en stemgeschiedenis downloaden vanuit het tablad "Instellingen".
                                                </p>
                                            </div>
                                        </div>
                                        <div className="mt-5 sm:mt-0 sm:ml-6 sm:flex sm:flex-shrink-0 sm:items-center">
                                            <button
                                                onClick={() => manageMeeting("end")}
                                                className="inline-flex items-center justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 font-medium text-red-700 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:text-sm disabled:hover:cursor-not-allowed disabled:opacity-50"
                                                disabled={status == "ended" ? true : false}

                                            >
                                                STOP
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h1 className="text-xl font-semibold text-gray-900 mt-10">Stemmen</h1>
                        <div className="mt-2 relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-6 hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                            <div className="bg-white shadow sm:rounded-lg">
                                <div className="px-4 py-5 sm:p-6">
                                    <div className="sm:flex sm:items-start sm:justify-between">
                                        <div>
                                            <h3 className="text-lg font-medium leading-6 text-gray-900">Voting aanmaken & starten</h3>
                                            <div className="mt-2 max-w-xl text-sm text-gray-500">
                                                <p>
                                                    Vul een titel in voor eigen referentie. Eens de voting start kan deze enkel nog gestopt worden.<br />
                                                </p>
                                                <input
                                                    type="text"
                                                    className="mt-2 w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                    placeholder="Voting titel"
                                                    value={votingTitle}
                                                    onChange={(e) => setVotingTitle(e.target.value)}
                                                    disabled={currentVote != 0}
                                                    onFocus={() => setVotingTitle("Vote " + (questions.length + 1))}
                                                />
                                            </div>
                                        </div>
                                        <div className="mt-5 sm:mt-0 sm:ml-6 sm:flex sm:flex-shrink-0 sm:items-center">
                                            {currentVote == 0 ?
                                                <a
                                                    onClick={() => createQuestion()}
                                                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-green-100 px-4 py-2 font-medium text-green-700 hover:bg-green-200 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-sm"
                                                >
                                                    AANMAKEN
                                                </a>
                                                :
                                                <div>
                                                    <div className="w-full text-right">
                                                        <a
                                                            onClick={() => stopQuestion()}
                                                            className="inline-flex items-center justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 font-medium text-red-700 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:text-sm"
                                                        >
                                                            STOP VOTING
                                                        </a>
                                                    </div>
                                                    <div className="mt-3">
                                                        <VoteComponent vote={currentVotes} addVote={addVoteManual} subVote={subVoteManual} />
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-white shadow sm:rounded-lg mt-5">
                                <div className="px-4 py-5 sm:p-6">
                                    <h3 className="text-lg font-medium leading-6 text-gray-900">Votings</h3>
                                    <div className="mt-2 text-sm text-gray-500">
                                        <p>
                                            Een lijst van voorgaande votings en hun resultaten.<br />
                                            Je kunt de votinggeschiedenis downloaden vanuit het tablad "Instellingen".
                                        </p>
                                        <div className="mt-12">
                                            {questions ? questions.map((voting) => (
                                                <div className="flex w-full border-t">
                                                    <VoteComponent voting={voting} />
                                                </div>
                                            )) : null}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                </div>
            </main>
            <div
                className={`w-1/4 bg-gradient-to-r from-[#392b5f] to-[#321548] h-[calc(100vh-${scrollTop > 54 ? "0": "54"})]`}
                style={
                    { marginTop: `calc(${scrollTop}px - ${scrollTop > 54 ? "54": "0"}px)` }
                }>
                <div className={`p-6 h-[calc(100vh-${scrollTop > 54 ? "0": "54"})]`}>
                    <ChatComponent chat={chat} message={message} setMessage={setMessage} sendNewChat={sendNewChat} paddingTop={scrollTop > 54 ? "0" : 54} />
                </div>
            </div>
        </div>




    )
}