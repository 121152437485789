import { useEffect, useState } from "react"
import { useRecoilState } from 'recoil';
import { useParams, Navigate } from "react-router-dom";

//import services
import AuthService from "../../services/auth.service";

//import datastores
import { loggedInState } from '../../data/loggedIn.atom';

export default function TokenView() {
    const [loggedIn, setLoggedIn] = useRecoilState(loggedInState);
    const [failed, setFailed] = useState(false);
    const {token} = useParams();

    useEffect(() => {
        AuthService.loginToken(token)
            .then(response => {
                setLoggedIn(true);
            })
        .catch(err => {
            setFailed(true);
        });
    }, []);

    return (
        <>
            {failed 
            ? <>De code is niet geldig.</>
            : <></>}
            {loggedIn
            ? <Navigate to="/" />
            : <></>}
        </>
    )
}