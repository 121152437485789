import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

//import services
import AuthService from "../../services/auth.service";

//import components
import HeaderComponent from "../../components/header.component";
import Footer from "../../components/footer.component";

//import assets
import { Link } from "react-router-dom";
import LegendMeetingStatus from "../../components/legend-meeting-status.component";
import Spinner from "../../components/ui/spinner.component";

export default function AuthView() {
    const { t } = useTranslation();
    const [email, setEmail] = useState("");
    const [TokenRequested, setTokenRequested] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        document.title = "Assemblo - Online vergaderingen";
    });

    function login() {
        setIsLoading(true)
        AuthService.requestToken(email)
            .then((response) => {
                setTokenRequested(true);
                setIsLoading(false)
            })
            .catch((err) => {
                setIsLoading(false)
                console.log(err);
            });
    }

    return (
        <section className="bg-gray-50 mx-auto h-full">
            <HeaderComponent
                text={t("home.header.publicheader")}
                subtext={t("home.header.publicsub")}
                name={""}
                explainer
            />
            <main className="w-full mx-auto absolute -mt-80 z-30">
                <div className="w-11/12 max-w-3xl mx-auto py-6 sm:px-6 lg:px-8 s">
                    <div className="px-4 py-6 sm:px-0 bg-white rounded-2xl shadow-md">
                        <section className="text-center px-10 lg:px-20">
                            {!TokenRequested ? (
                                <div className="relative">
                                    <h1 className="text-3xl font-semibold text-gray-900 pt-10">
                                        {t("auth.welcome")}
                                    </h1>
                                    <h1 className="text-3xl font-semibold text-gray-900">-</h1>
                                    <p className="text-gray-900">
                                        {t("auth.sub1")}{" "}
                                        <span className="text-orange-600 font-bold">
                                            {t("auth.magic")}
                                        </span>{" "}
                                        {t("auth.sub2")}
                                    </p>
                                    <div>
                                        <div className="mt-8">
                                            <input
                                                type="email"
                                                name="email"
                                                id="email"
                                                className="block w-full bg-slate-100 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                placeholder={t("auth.email")}
                                                onChange={(e) => setEmail(e.target.value)}
                                                value={email}
                                            />
                                        </div>
                                    </div>
                                    <div className="block mt-18">
                                        {!isLoading ?
                                            <Link
                                                onClick={login}
                                                className="button-primary inline-block w-max text-white mt-10 mb-14"
                                            >
                                                {t("auth.submit")}
                                            </Link>
                                            :
                                            <div className="mt-10 mb-14">
                                                <Spinner />
                                            </div>
                                        }
                                    </div></div>
                            ) : (
                                <div className="mb-10">
                                    <h1 className="text-3xl font-semibold text-gray-900 pt-10">
                                        {t("auth.good")}
                                    </h1>
                                    <h1 className="text-3xl font-semibold text-gray-900">-</h1>
                                    <p className="text-gray-900">
                                    {t("auth.sent")}
                                        <br /><br />
                                        {t("auth.sentsub")}{" "}<span className="text-orange-600 font-bold">{email}</span>{" "}
                                        {t("auth.sentsub2")}<br />
                                    </p>
                                </div>
                            )}
                        </section>
                        <section className="flex bg-slate-100 flex-col text-center -mb-6 rounded-bl-2xl rounded-br-2xl" >
                            <p className="mt-8 mb-4">{t("auth.rsvp")}:</p>{" "}
                            <LegendMeetingStatus />
                        </section>
                    </div>
                    <Footer limited={true} />
                </div>
            </main>
        </section>
    );
}
