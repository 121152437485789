import React, { useEffect, useRef, useState, useContext } from 'react';
import { useParams, useSearchParams } from "react-router-dom";
import { toast } from 'react-toastify';
import { Director, View } from '@millicast/sdk';
import { useRecoilState } from "recoil";
import canAutoPlay from 'can-autoplay';
import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next';
import { ReactHeight } from 'react-height';
import cn from 'classnames'

//import assets
import { ReactComponent as AssembloLogoHor } from "../assets/img/assemblo-logo-hor.svg";

//import services
import MeetingService from "../services/meeting.service";
import ChatService from "../services/chat.service";
import { socket, SocketContext } from "../services/socket.service";
import VotingService from '../services/voting.service';

//import components
import ChatComponent from "../components/chat.component";
import EndedComponent from "../components/ended.component";
import WaitingComponent from "../components/waiting.component";
import Spinner from "../components/ui/spinner.component";


//import datastores
import { meState } from "../data/me.atom";
import Toggle from '../components/ui/toggle.component';

export default function MeetingView() {
    const { t } = useTranslation();
    const [me] = useRecoilState(meState);

    const mainPlayer = useRef();
    const subPlayer = useRef();
    const frPlayer = useRef();

    const [lang, setLang] = useState("NL");

    const [status, setStatus] = useState("not started");
    const [chat, setChat] = useState([]);
    const [message, setMessage] = useState("");
    const [meeting, setMeeting] = useState({});
    const [meetingRefresh, setMeetingRefresh] = useState(0);

    const [voting, setVoting] = useState({});
    const [votingSelect, setVotingSelect] = useState("");
    const [globalVotes, setGlobalVotes] = useState({ "yes": 0, "no": 0, "abstain": 0 });


    const { id } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const socketprovider = useContext(SocketContext);
    const [autoplay, setAutoplay] = useState(false);

    const [mainPlaceholder, setMainPlaceholder] = useState(true);
    const [subPlaceholder, setSubPlaceholder] = useState(true);

    const [open, setOpen] = useState(true)
    const [beforeChat, setBeforeChat] = useState(0);

    //use effect to define language
    useEffect(() => {
        if (searchParams.get("lang")) {
            if (lang.toLowerCase() != searchParams.get("lang")) {
                setLang(searchParams.get("lang").toUpperCase())
            }
        }
        reloadAutoplay();
    })

    //use effect to load meeting
    useEffect(() => {
        MeetingService.getMeeting(id)
            .then(response => {
                setMeeting(response.data);
                document.title = response.data.title;
            })
            .catch(err => {
                toast.error(err.response.data.message);
            });
    }, []);

    useEffect(() => {
        MeetingService.status(id)
            .then(response => {
                setStatus(response.data);
            })
            .catch(err => {
                console.log(err);
            }
            );

        ChatService.getChats(id)
            .then(response => {
                setChat(response.data);
            }
            );

        VotingService.getVoting(id)
            .then(response => {
                setVoting(response.data);
                VotingService.getAllVotes(response.data.id)
                    .then(response => {
                        setGlobalVotes(response.data);
                    }
                    );
                if (response.data != null) {
                    VotingService.getVote(response.data.id)
                        .then(response => {
                            if (response.data.response != null) {
                                setVotingSelect(response.data.response);
                            }
                        })
                        .catch(err => {
                            console.log(err);
                        }
                        );
                }
            }
            );
    }, []);



    useEffect(() => {
        socketprovider.connect();
        socketprovider.socket.on('meeting', function (data) {
            if (data.id == id && data.status == "start") {
                setStatus("started");
            }
            if (data.id == id && data.status == "end") {
                setStatus("ended")
            }
        });

        socketprovider.socket.on('chat', function (data) {
            if (data.meeting == id) {
                setChat(chat => [...chat, { user: data.user, message: data.message, created_on: data.created_on }]);
            }
        });

        socketprovider.socket.on('question', function (data) {
            if (data.meeting_id == id) {
                setVotingSelect("");
                setVoting(data);
            }
        });

        socketprovider.socket.on('refresh', function (data) {
            if (data.user_id == me.id) {
                window.location.reload();
            }
        });

        socketprovider.socket.on('votes', function (data) {
            if (data.meeting_id == id) {
                setGlobalVotes({
                    yes: data.yes,
                    no: data.no,
                    abstain: data.abstain
                })
            }
        });


        return () => {
            socketprovider.socket.off('meeting');
            socketprovider.socket.off('chat');
            socketprovider.socket.off('question');
            socketprovider.socket.off('refresh');
            socketprovider.socket.off('votes');
            socketprovider.disconnect();
        }
    }, []);

    //use effect to load main and sub player
    useEffect(() => {
        if (meeting.title && meeting.accounttoken && status == "started") {
            const tokenGenerator = () =>
                Director.getSubscriber({
                    streamName: meeting.title,
                    streamAccountId: meeting.accounttoken,
                    unauthorizedSubscribe: true,
                });

            const millicastMainPlayer = new View(meeting.title, tokenGenerator);
            const millicastSubPlayer = new View(meeting.title, tokenGenerator);
            const millicastFrPlayer = new View(meeting.title, tokenGenerator);

            millicastFrPlayer.on("track", (event) => {
                frPlayer.current.srcObject = event.streams[0];
                frPlayer.current.play();
            });

            millicastMainPlayer.on("track", (event) => {
                if (autoplay) {
                    mainPlayer.current.srcObject = event.streams[0];
                    mainPlayer.current.play();
                    setMainPlaceholder(false);
                }
            });

            millicastSubPlayer.on("track", (event) => {
                if (autoplay) {
                    subPlayer.current.srcObject = event.streams[0];
                    subPlayer.current.play();
                    setSubPlaceholder(false);
                }

            });

            try {
                millicastMainPlayer.connect({
                    pinnedSourceId: "mainPlayer",
                });
            } catch (e) {
                console.log("Connection failed, handle error", e);
                millicastMainPlayer.reconnect();
            }


            if (lang == "NL") {
                millicastSubPlayer.stop()
                millicastFrPlayer.stop()

                //connect to dutch audio and webcam
                try {
                    millicastSubPlayer.connect({
                        pinnedSourceId: "subPlayer",
                        disableAudio: false,
                    });

                } catch (e) {
                    console.log("Connection failed, handle error", e);
                    millicastSubPlayer.reconnect();
                }

            } else if (lang == "FR") {
                millicastSubPlayer.stop()
                millicastFrPlayer.stop()

                //connect to dutch audio and webcam + disable audio
                try {
                    millicastSubPlayer.connect({
                        pinnedSourceId: "subPlayer",
                        disableAudio: true,
                    });
                } catch (e) {
                    console.log("Connection failed, handle error", e);
                    millicastSubPlayer.reconnect();
                }

                //connect to french audio
                try {
                    millicastFrPlayer.connect({
                        pinnedSourceId: "frAudio",
                        disableVideo: true,
                    });
                } catch (e) {
                    console.log("Connection failed, handle error", e);
                    millicastFrPlayer.reconnect();
                }
            } else {
                alert("Ongeldige taal geselecteerd.")
            }

        } else {
            console.log("No stream or account");
        }
    }, [meeting, meetingRefresh, status]);


    function sendNewChat() {
        if (!message == "") {
            socketprovider.socket.emit('chat', { id: id, message: message });
            setMessage("");
        }
    }

    function vote(choice) {
        VotingService.vote(id, choice, voting.id)
            .then(response => {
                setVotingSelect(response.data.response);
                console.log(response.data);
            }
            )
            .catch(err => {
                console.log(err);
            }
            );
    }

    function removeVote() {
        VotingService.removeVote(id, votingSelect, voting.id)
            .then(response => {
                setVotingSelect("");
                console.log(response.data);
            }
            )
            .catch(err => {
                console.log(err);
            }
            );
    }

    function switchLang(slang) {
        if (slang != lang) {
            window.location.href = "/meeting/" + id + "?lang=" + slang;
        }
    }

    function reloadAutoplay() {
        if (!autoplay) {
            canAutoPlay.video().then(({ result }) => {
                if (result === true) {
                    setAutoplay(true);
                    setMeetingRefresh(meetingRefresh + 1);
                    setOpen(false);
                }
            })
        }
    }

    return (
        <>
            {status == "started"
                ?
                <div className="flex">
                    <div className="lg:w-4/5 md:3/4 col-1">
                        <div className="flex h-screen">
                            <video className={`w-full aspect-auto ${mainPlaceholder === true ? "hidden" : "block"}`} autoplay ref={mainPlayer}>
                            </video>
                            {mainPlaceholder === true ?
                                (<div className="w-full h-full bg-gray-800">
                                    <div className="mt-96">
                                        <AssembloLogoHor className="h-24 mx-auto mt-4" />
                                        <h1 className="text-center text-white text-lg font-bold">{t("meeting.busy.biepboepbap")}</h1>
                                        <h3 className='text-center text-white text-md font-semibold'>{t("meeting.busy.backsoon")}</h3>
                                        <div className="mt-8">
                                            <Spinner />
                                        </div>
                                    </div>
                                </div>
                                ) : null}
                        </div>
                    </div>
                    <div className="lg:w-1/5 md:1/4 bg-gradient-to-r from-[#392b5f] to-[#4e2071] h-screen">
                        <ReactHeight onHeightReady={height => setBeforeChat(height)}>
                            <div className="">
                                <AssembloLogoHor className="h-12 mx-auto mt-4" />
                            </div>
                            <div className="py-4 px-6">
                                <Toggle options={[{ label: "NL", value: () => switchLang("NL"), }, { label: "FR", value: () => switchLang("FR"), }]} defaultChecked={lang == "FR" ? true : false} />
                                <video className={`w-full aspect-auto rounded-lg shadow-md mt-2 ${subPlaceholder === true ? "hidden" : "block"}`} autoplay ref={subPlayer}>
                                </video>
                                {subPlaceholder === true ? (
                                    <div className="w-full h-36 rounded-lg shadow-md bg-gray-800 px-2 mt-4">
                                        <div className="w-full pt-12">
                                            <h1 className="text-center text-white text-md font-bold">{t("meeting.busy.biepboepbap")}</h1>
                                            <h3 className='text-center text-white text-xs font-semibold'>{t("meeting.busy.backsoon")}</h3>
                                        </div>
                                    </div>
                                ) : null}
                                <video className="hidden w-full aspect-auto rounded-lg shadow-md" autoplay ref={frPlayer}>
                                </video>
                            </div>
                        </ReactHeight>
                        <div className="py-4 px-6">
                            <ChatComponent chat={chat} message={message} setMessage={setMessage} sendNewChat={sendNewChat} paddingTop={beforeChat} />
                        </div>
                    </div>

                    {voting ?
                        voting.live == true ?
                            votingSelect == "" ?
                                <div className='absolute bottom-0 z-30 w-96 flex flex-col text-white left-1/2 transform -translate-x-1/2 shadow-lg'>
                                    <div className='w-full flex'>
                                        <button
                                            className="bg-lime-400 py-5 px-4 rounded-tl transition-all hover:w-52 grow"
                                            onClick={() => { vote("yes") }}>{t('meeting.start.voting.votes.yes').toUpperCase()}</button>
                                        <button
                                            className="bg-red-500 py-5 px-4 rounded-tr transition-all hover:w-52 grow"
                                            onClick={() => { vote("no") }}>{t('meeting.start.voting.votes.no').toUpperCase()}</button>
                                    </div>
                                    <div className='w-full flex'><button className="bg-slate-600 grow py-5 px-4"
                                        onClick={() => { vote("abstain") }}>{t('meeting.start.voting.votes.abstain').toUpperCase()}</button></div>


                                </div> :
                                <div className='absolute bottom-0 z-30 bg-white w-96 flex flex-col px-4 py-4 rounded left-1/2 transform -translate-x-1/2'>
                                    <div className='font-bold'>{t('meeting.start.voting.title')}</div>
                                    <div className='flex justify-start mt-2'>{t('meeting.start.voting.yourvote')}
                                    <div className='ml-2'>
                                        {votingSelect === "yes" ?
                                            <span className="inline-flex items-center rounded-md bg-lime-400 px-2.5 py-0.5 text-sm font-medium text-white">
                                                {t('meeting.start.voting.votes.yes')}
                                            </span>
                                            : null}

                                        {votingSelect === "no" ?
                                            <span className="inline-flex items-center rounded-md bg-red-500 px-2.5 py-0.5 text-sm font-medium text-white">
                                                {t('meeting.start.voting.votes.no')}
                                            </span>
                                            : null}

                                        {votingSelect === "abstain" ?
                                            <span className="inline-flex items-center rounded-md bg-slate-600 px-2.5 py-0.5 text-sm font-medium text-white">
                                                {t('meeting.start.voting.votes.abstain')}
                                            </span>
                                            : null}
                                    </div>
                                        <span className='ml-auto mt-auto text-xs text-gray-400 underline' onClick={() => removeVote()}>{t('meeting.start.voting.change')}</span></div>
                                    <div className=''>
                                        <div className="h-16 w-96 bg-lime-400 -mb-4 mt-2 -ml-4 rounded-bl flex justify-center items-center shadow-lg">
                                            <div className="w-1/3 h-full bg-lime-400" style={{ width: `${(globalVotes["yes"] / (globalVotes["yes"] + globalVotes["no"] + globalVotes["abstain"])) * 100}%` }}>
                                                <p className="text-white text-center mt-2">
                                                    {globalVotes["yes"] > 0 ? `${Math.round((globalVotes["yes"] / (globalVotes["yes"] + globalVotes["no"] + globalVotes["abstain"])) * 100)}%` : null}
                                                </p>
                                                <p className="text-white text-center">
                                                    {globalVotes["yes"] > 1 ? globalVotes["yes"] + " " + t('meeting.start.voting.multivotes') : globalVotes["yes"] == 1 ? globalVotes["yes"] + " " + t('meeting.start.voting.vote') : null}
                                                </p>
                                            </div>
                                            <div className="w-1/3 h-full bg-red-500" style={{ width: `${(globalVotes["no"] / (globalVotes["yes"] + globalVotes["no"] + globalVotes["abstain"])) * 100}%` }}>
                                                <p className="text-white text-center mt-2">
                                                    {globalVotes["no"] > 0 ? `${Math.round((globalVotes["no"] / (globalVotes["yes"] + globalVotes["no"] + globalVotes["abstain"])) * 100)}%` : null}
                                                </p>
                                                <p className="text-white text-center">
                                                    {globalVotes["no"] > 1 ? globalVotes["no"] + " " + t('meeting.start.voting.multivotes') : globalVotes["no"] == 1 ? globalVotes["no"] + " " + t('meeting.start.voting.vote') : null}
                                                </p>                                            </div>
                                            <div className="w-1/3 h-full bg-slate-600" style={{ width: `${(globalVotes["abstain"] / (globalVotes["yes"] + globalVotes["no"] + globalVotes["abstain"])) * 100}%` }}>
                                                <p className="text-white text-center mt-2">
                                                    {globalVotes["abstain"] > 0 ? `${Math.round((globalVotes["abstain"] / (globalVotes["yes"] + globalVotes["no"] + globalVotes["abstain"])) * 100)}%` : null}
                                                </p>
                                                <p className="text-white text-center text-clip">
                                                    {globalVotes["abstain"] > 1 ? globalVotes["abstain"] + " " + t('meeting.start.voting.multivotes') : globalVotes["abstain"] == 1 ? globalVotes["abstain"] + " " + t('meeting.start.voting.vote') : null}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            : null
                        : null}

                </div>
                : status == "ended"
                    ? <EndedComponent meeting_id={id} />
                    : <WaitingComponent date={meeting.date} />
            }
            {!autoplay && status == "started" ? (
                <Transition.Root show={open} as={Fragment}>
                    <Dialog as="div" className="relative z-10" onClose={() => (console.log("Click outside of modal."))}>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        <div className="fixed inset-0 z-10 overflow-y-auto">
                            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                >
                                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                                        <div>
                                            <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                                                <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                                            </div>
                                            <div className="mt-3 text-center sm:mt-5">
                                                <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                                    {t("meeting.busy.ready")}
                                                </Dialog.Title>
                                                <div className="mt-2">
                                                    <p className="text-sm text-gray-500">
                                                        {t("meeting.busy.readysub")}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-5 sm:mt-6">
                                            <button
                                                type="button"
                                                className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                                                onClick={() => reloadAutoplay()}
                                            >
                                                {t("meeting.busy.readybutton")}
                                            </button>
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </Dialog>
                </Transition.Root>
            ) : null}

        </>

    )
}